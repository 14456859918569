/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
/* eslint react/prop-types: 0 */
import React from 'react';
import styled from 'styled-components';
import TopNavBarV6 from './TopNavBarV6';
import TopSimpleNavBarV6 from './TopSimpleNavBarV6';
import Hello from './Hello';
import GlobalStyle from './GlobalStyle';
import Theme from './Theme';
// import AnnouncementSection from './Shared/v6_announcement/AnnouncementSection';
import parseQuery from './Shared/parseQuery';
import CopyCodeButton from '../utils/CopyCodeButton';
import '../styles/styles.scss';
import PostconNavBar from './PostconNavBar';
import TopProductNavBar from './TopProductNavBar';

const Main = styled.main`
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
`;

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return undefined;
};

const setReferrerCookie = () => {
  const referrerUrl = document.referrer !== '' ? document.referrer : 'null';
  const now = new Date();
  const timeStamp = now.setDate(now.getDate() + 30);
  const expiration = new Date(timeStamp).toUTCString();
  const currentCookie = getCookie('referrer_url');
  if (!referrerUrl.split('?')[0].includes('postman.com')) {
    if (
      referrerUrl !== '' ||
      currentCookie === 'null' ||
      currentCookie === undefined
    ) {
      document.cookie =
        'referrer_url=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;secure';
      document.cookie = `referrer_url=${referrerUrl};expires=${expiration};path=/;secure;`;
    }
  }
};

const setGclidCookie = () => {
  const { search } = window.location;
  const queries = parseQuery(search);
  if (queries.gclid) {
    const now = new Date();
    const timeStamp = now.setDate(now.getDate() + 30);
    const expiration = new Date(timeStamp).toUTCString();
    document.cookie =
      'gclid=; expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;secure';
    document.cookie = `gclid=${queries.gclid};expires=${expiration};path=/;secure;`;
  }
};

const isTrackAccepted = () =>
  window.OnetrustActiveGroups && window.OnetrustActiveGroups.includes('C0004');

// Utilize this function to change navbars using the navbarType
function Nav(props) {
  const { navbarType, navbarBackground } = props;
  switch (navbarType) {
    case 'product':
      return <TopProductNavBar />;
    case 'simple':
      return <TopSimpleNavBarV6 navbarBackground={navbarBackground} />;
    case 'postcon':
      return <PostconNavBar />;
    default:
      return <TopNavBarV6 />;
  }
}

const runtime = typeof document === 'object';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props,
      trackingAccepted: false
    };
  }

  componentDidMount() {
    const testint = setInterval(() => {
      // Use this conditional to initiate a third party service if it requires consent
      if (isTrackAccepted()) {
        clearInterval(testint);
        this.setState({
          trackingAccepted: true
        });
        setReferrerCookie();
        setGclidCookie();
      }
    }, 1000);
    CopyCodeButton();

    // @desc /legal/cookies/ - onclick handler for "Manage Cookies" link to open preference center
    if (
      (runtime && window.location.href.includes('/legal/cookies/')) ||
      (runtime && window.location.href.includes('/legal/privacy-choices'))
    ) {
      (function openOneTrustCookieList() {
        const hrefTag = document.querySelector('#one-trust-manage-cookies');
        if (hrefTag) {
          hrefTag.onclick = () => {
            // https://community.cookiepro.com/s/article/UUID-504c8e73-f556-1d8a-a1d8-b89fd40bb8eb?language=en_US&topicId=0TO3q000000kIjDGAU
            const preferenceCenter = document.getElementById('onetrust-pc-sdk');
            const overlay = document.querySelector(
              '.onetrust-pc-dark-filter.ot-hide'
            );
            preferenceCenter.style =
              'display: block !important; height: 618px !important';
            overlay.style = 'display: block !important;';
          };
        }
      })();
    }
  }

  render() {
    const { trackingAccepted, children, navbarType, navbarBackground } =
      this.state;
    return (
      <>
        <GlobalStyle />
        <Theme>
          <Main>
            <Hello />
            <Nav navbarType={navbarType} navbarBackground={navbarBackground} />
            {/* <AnnouncementSection /> */}
            {children}
            {/* Use trackingAccepted if we need to use a third party service that requires consent */}
            {trackingAccepted ? null : null}
          </Main>

          {/* <UtmCleanUrl /> */}
        </Theme>
      </>
    );
  }
}

export default Layout;
